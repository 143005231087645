/** @jsx jsx */
import { jsx, Styled, components, useThemeUI, Container } from "theme-ui"
import { Helmet } from "react-helmet"

import {
  TypeScale,
  TypeStyle,
  ColorPalette,
  FontFamily,
} from "@theme-ui/style-guide"

import Components from "../components/components.mdx"
import NavMenu from "../components/navmenu.mdx"
import Content from "../components/content.mdx"

import FullBG from '../components/FullBackground'
//import StyledFullBackground from '../components/FullBackground'


export default () => {
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <Helmet>
        <title>Green Meadows Flags</title>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Architects+Daughter|Montserrat:400,700|Poppins:400,700,900|Roboto:400,600"
        />
				<link href="favicon.ico" rel="shortcut icon" type="image/x-icon" />
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
				<link rel="manifest" href="/site.webmanifest"/>
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
				<meta name="msapplication-TileColor" content="#da532c"/>
				<meta name="theme-color" content="#ffffff"/>
      </Helmet>
      <FullBG sx={{ maxWidth: 'auto', mx: 'auto', p: 50, }} >
          <Styled.h1 sx={{ color: "#090", textAlign: "center", fontSize: "3em" }}>
            Green Meadows Flags
          </Styled.h1>
          <NavMenu sx={{ maxWidth: 800, mx: 'auto', p: 50, }} />
        <Styled.root sx={{ maxWidth: 800, mx: 'auto', p: 50, }} >
          <Content />
        </Styled.root>
      </FullBG>
    </div>
  )
}


/*
      <StyledFullBackground>
      </StyledFullBackground>
      <Container
        sx={{
          p: 30,
        }}
      >
      </Container>

const ThemeJson = () => {
  const theme = useThemeUI()
  return (
    <textarea
      value={JSON.stringify(theme, null, 2)}
      rows={16}
      readOnly
      sx={{
        width: "100%",
        fontFamily: "monospace",
        bg: "muted",
        p: 2,
        border: 0,
        borderRadius: "sketchy3",
      }}
    />
  )
}

		<StyledFullBackground>
		</StyledFullBackground>
          <Styled.h2>Typography</Styled.h2>
          <TypeStyle fontSize={1}>
            Body: <FontFamily name="body" />
          </TypeStyle>
          <Styled.h2>Type Scale</Styled.h2>
          <TypeScale />
          <Styled.h2>Raw JSON</Styled.h2>
          <ThemeJson />
*/
