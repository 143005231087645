import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

/**
 * In this functional component a fullscreen <BackgroundImage />  is created.
 * @param className   string    className(s) from styled-components.
 * @param children    nodes     Child-components.
 * @return {*}
 * @constructor
 */
const FullBackground = ({ className, children }) => {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "background.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Single Image Data
  const imageData = desktop.childImageSharp.fluid

  return (
      <BackgroundImage
        Tag="section"
        className={className}
        fluid={imageData}
        backgroundColor={`#ffffff`}
        // This is the hover text
        //title="Fullscreen Background"
        //aria-label="Fullscreen Background"
        id="fullscreenbg"
        role="img"
        preserveStackingContext={true}
      >
        {children}
      </BackgroundImage>
  )
}

const FullBG = styled(FullBackground)`
  width: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover; /* Resize the background image to cover the entire container */
`

export default FullBG
/*
  width: 100%;
  height: 100vh;
  display: flex;  // reorganizes contents, bad
  align-items: center;
  justify-content: center;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
    <StyledFullScreenWrapper>
    </StyledFullScreenWrapper>
*/
