import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import { NavLink, Box, Flex } from "theme-ui";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {/*
     import { Message, NavLink, Divider, Container, Box, Button, Card, Text, Image, Slider, Label, Flex, Checkbox, Select, Textarea, Radio, Input, Link, Progress, Badge, Alert } from "theme-ui"
     <Box pb={30} p={2} sx={{ flex: '1 1 auto' }}>
     */}

    <Flex as='nav' sx={{
      justifyContent: 'center'
    }} mdxType="Flex">
      <Box pb={30} p={2} sx={{
        maxWidth: 800
      }} mdxType="Box">
    <NavLink href='#!' p={3} mdxType="NavLink">
      Home
    </NavLink>
    <NavLink href='https://photos.greenmeadowsflags.org/' p={3} mdxType="NavLink">
      Photos
    </NavLink>
    <NavLink href='#about-us' p={3} mdxType="NavLink">
      About
    </NavLink>
    <NavLink href='#contact-us' p={3} mdxType="NavLink">
      Contact
    </NavLink>
      </Box>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      