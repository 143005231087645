import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import { Message, NavLink, Divider, Container, Box, Button, Card, Text, Image, Slider, Label, Flex, Checkbox, Select, Textarea, Radio, Input, Link, Progress, Badge, Alert } from "theme-ui";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Green Meadows Flags is a Boy Scout (now `}<a parentName="p" {...{
        "href": "https://scouting.org",
        "title": "Scouting America"
      }}>{`Scouting America`}</a>{` previously `}<a parentName="p" {...{
        "href": "https://scoutsbsa.org",
        "title": "Scouts BSA"
      }}>{`Scouts BSA`}</a>{`) fundraiser in the `}<a parentName="p" {...{
        "href": "https://fallsatgreenmeadows.com",
        "title": "Falls at Green Meadows"
      }}>{`Falls at Green Meadows`}</a>{` neighborhood of `}<a parentName="p" {...{
        "href": "https://www.cityofkaty.com",
        "title": "City of Katy"
      }}>{`Katy, Texas`}</a>{`.  The Scout serving your house will post a flag in your yard during the six holidays below.  Subscription cycles run 12-months instead of a calendar year.`}</p>
    <table style={{
      paddingBottom: 20
    }}>
		<thead>
				<tr>
						<th colSpan="2">Six Holidays</th>
				</tr>
		</thead>
		<tbody>
				<tr>
						<td>Presidents' Day</td>
						<td>Independence Day</td>
				</tr>
				<tr>
						<td>Memorial Day</td>
						<td>Labor Day</td>
				</tr>
				<tr>
						<td>Flag Day</td>
						<td>Veterans' Day</td>
				</tr>
		</tbody>
    </table>
    <p>{`The price for an American flag varies by Scout; a Premium Texas+American flag combo is $100.  Most Scouts accept cash, check, cash.app, `}<a parentName="p" {...{
        "href": "https://paypal.me/maifeld/30",
        "title": "PayPal Green Meadows Flags"
      }}>{`PayPal`}</a>{`, or a credit card through our payment processor Stripe.`}</p>
    <table style={{
      paddingBottom: 20
    }}>
		<thead>
				<tr>
						<th colSpan="2">Price For 12 Months</th>
				</tr>
		</thead>
		<tbody>
				<tr>
						<td>American Flag</td>
						<td>varies</td>
				</tr>
				<tr>
						<td>Texas+American Flag</td>
						<td>$100</td>
				</tr>
		</tbody>
    </table>
    <p>{`Good Scouts share and we are no exception.  For efficiency and division-of-labor, we have agreed which Scouts take which streets in the Falls at Green Meadows.  Generally, east of the bayou is Nortons and Maifelds (the oldest part), west of the bayou and south is Chilton, west of the bayou and north is Escamilla (with some exceptions).  Find your street alphabetically and contact the family listed.`}</p>
    <table style={{
      paddingBottom: 20
    }}>
		<thead>
				<tr>
						<th>Street</th>
						<th>Family</th>
				</tr>
		</thead>
		<tbody>
				<tr>
						<td>Arrowbrook Cove Ln</td>
						<td><a href="#Escamilla">Escamilla</a></td>
				</tr>
				<tr>
						<td>Asbury Brook Ct</td>
						<td><a href="#Chilton">Chilton</a></td>
				</tr>
				<tr>
						<td>Banyon Gulch Ln</td>
						<td><a href="#Chilton">Chilton South</a> and <a href="#Turner">Escamilla North</a></td>
				</tr>
				<tr>
						<td>Cedar Hawk Ct</td>
						<td><a href="#Chilton">Chilton</a></td>
				</tr>
				<tr>
						<td>Cottonwood Crest Ln</td>
						<td><a href="#Chilton">Chilton</a></td>
				</tr>
				<tr>
						<td>Eden Terrace Ln</td>
						<td><a href="#Escamilla">Escamilla</a></td>
				</tr>
				<tr>
						<td>Fairway Glen Ln</td>
						<td><a href="#Escamilla">Escamilla</a></td>
				</tr>
				<tr>
						<td>Green Meadows Ln</td>
						<td><a href="#Maifeld">Maifeld</a></td>
				</tr>
				<tr>
						<td>Harbor Breeze Ln</td>
						<td><a href="#Escamilla">Escamilla</a></td>
				</tr>
				<tr>
						<td>Harkness Oak Ln</td>
						<td><a href="#Escamilla">Escamilla</a></td>
				</tr>
				<tr>
						<td>Hollow Bay Ct</td>
						<td><a href="#Chilton">Chilton</a></td>
				</tr>
				<tr>
						<td>Holly Manor Ct</td>
						<td><a href="#Norton">Norton</a></td>
				</tr>
				<tr>
						<td>Nectar Grove Ct</td>
						<td><a href="#Chilton">Chilton</a></td>
				</tr>
				<tr>
						<td>Newbury Island Ln</td>
						<td><a href="#Escamilla">Escamilla</a></td>
				</tr>
				<tr>
						<td>Poplar Meadows Ln</td>
						<td><a href="#Escamilla">Escamilla</a></td>
				</tr>
				<tr>
						<td>Sterling Springs Ln</td>
						<td><a href="#Escamilla">Escamilla</a></td>
				</tr>
				<tr>
						<td>Summer Gardens Ln</td>
						<td><a href="#Norton">Norton</a></td>
				</tr>
				<tr>
						<td>Sunstone Falls Ln</td>
						<td><a href="#Escamilla">Escamilla</a></td>
				</tr>
				<tr>
						<td>Verde Place Ln</td>
						<td><a href="#Maifeld">Maifeld</a></td>
				</tr>
				<tr>
						<td>Wellbrook Falls Ln</td>
						<td><a href="#Escamilla">Escamilla</a></td>
				</tr>
				<tr>
						<td>Wild Hollow Ct</td>
						<td><a href="#Escamilla">Escamilla</a></td>
				</tr>
				<tr>
						<td>Windy Hills Ln</td>
						<td><a href="#Escamilla">Escamilla</a></td>
				</tr>
		</tbody>
    </table>
    <p>{`Here is our contact information, alphabetically.`}</p>
    <table style={{
      paddingBottom: 20
    }}>
		<thead>
				<tr>
						<th>Family</th>
						<th>Mobile</th>
						<th>email</th>
				</tr>
		</thead>
		<tbody>
				<tr>
						<td><a id="Chilton">Chilton</a></td>
						<td><a href="tel:2817050976">281/705-0976</a></td>
						<td><a href="mailto:ZacharyMChilton@gmail.com?cc=subscriptions@greenmeadowsflags.org&amp;subject=Subscription Question&amp;body=Zach, I am interested in">ZacharyMChilton@gmail.com</a></td>
				</tr>
				<tr>
						<td><a id="Escamilla">Escamilla</a></td>
						<td><a href="tel:7133038613">713/303-8613</a></td>
						<td><a href="mailto:almagesva@yahoo.com?cc=subscriptions@greenmeadowsflags.org&amp;subject=Subscription Question&amp;body=Alma, I am interested in">almagesva@yahoo.com</a></td>
				</tr>
				<tr>
						<td><a id="Maifeld">Maifeld</a></td>
						<td><a href="tel:7403501876">740/350-1876</a></td>
						<td><a href="mailto:mitch@maifeld.name?cc=subscriptions@greenmeadowsflags.org&amp;subject=Subscription Question&amp;body=Mitch, I am interested in">mitch@maifeld.name</a></td>
				</tr>
				<tr>
						<td><a id="Norton">Norton</a></td>
						<td><a href="tel:8324147261">832/414-7261</a></td>
						<td><a href="mailto:gina.norton@ymail.com?cc=subscriptions@greenmeadowsflags.org&amp;subject=Subscription Question&amp;body=Gina, I am interested in">gina.norton@ymail.com</a></td>
				</tr>
		</tbody>
    </table>
    <h1 id="about-us">About Us</h1>
    <hr></hr>
    <h2>{`Wilson Maifeld`}</h2>
    <Card sx={{
      maxWidth: 500
    }} mdxType="Card">
  <Text mdxType="Text">
    Wilson is a Star rank Scout in Troop 567 who enjoys dogs, reading, and Legos&reg;.  He lives on Green Meadows Ln near the clubhouse.
  </Text>
    </Card>
    <h2>{`Henry Chilton`}</h2>
    <Card sx={{
      maxWidth: 500
    }} mdxType="Card">
  <Text mdxType="Text">
    Henry enjoys bike riding, football, and reading.
  </Text>
    </Card>
    <h2>{`Gabriella Escamilla`}</h2>
    <Card sx={{
      maxWidth: 500
    }} mdxType="Card">
  <Text mdxType="Text">
    Gabriella is a Scout in Troop 1861.
  </Text>
    </Card>
    <h2>{`Daniel Escamilla`}</h2>
    <Card sx={{
      maxWidth: 500
    }} mdxType="Card">
  <Text mdxType="Text">
    Daniel is a Star rank Scout in Troop 567 who enjoys cats, bugs, and reading.
  </Text>
    </Card>
    <h2>{`Seiger & Halen Norton`}</h2>
    <Card sx={{
      maxWidth: 500
    }} mdxType="Card">
  <Text mdxType="Text">
    Seiger & Halen Norton are Scouts in Troop 925 and live on Holly Manor Lane.
  </Text>
    </Card>
    <h1 id="contact-us">Contact Us</h1>
    <hr></hr>
    <p>{`Email us at `}<a parentName="p" {...{
        "href": "mailto:subscriptions@greenmeadowsflags.org?subject=Customer%20Inquiry&body=Hello,%20I%20am%20interested%20in%20your%20services!"
      }}>{`subscriptions@greenmeadowsflags.org`}</a>{` or call: `}<a href="tel:2816763881">{`(281) 676-3881`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      